///
/// @file base/_helpers.scss
///
/// Presentational classes
///

// @todo remove the extends - this method is deprecated

///
/// Hide from both screenreaders and browsers: h5bp.com/u
///

.hidden {
  display: none !important;
  visibility: hidden;
}

///
/// Hide only visually, but have it available for screenreaders: h5bp.com/v
///

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/// Extends the .visuallyhidden class to allow the element to be focusable
/// when navigated to via the keyboard: h5bp.com/p
///

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

///
/// Hide visually and from screenreaders, but maintain layout
///

.invisible {
  visibility: hidden;
}

///
/// Clearfix: contain floats
///
/// For modern browsers
/// 1. The space content is one way to avoid an Opera bug when the
///    `contenteditable` attribute is included anywhere else in the document.
///    Otherwise it causes space to appear at the top and bottom of elements
///    that receive the `clearfix` class.
/// 2. The use of `table` rather than `block` is only necessary if using
///    `:before` to contain the top-margins of child elements.
///

.clearfix:before,
.clearfix:after {
  content: ' '; /* 1 */
  display: table; /* 2 */
}

.clearfix:after {
  clear: both;
}

///
/// Text Alignment
///
.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

///
/// Mobile Text Alignment
///
@include breakpoint($small-down) {
  .mobile-align-left {
    text-align: left;
  }
  .mobile-align-center {
    text-align: center;
  }
  .mobile-align-right {
    text-align: right;
  }
}

///
/// Floats & Clearing floats
///
.float-left {
  float: left;
}

.float-right {
  float: right;
}

.float-none {
  float: none;
}

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

.clear-both,
.clear {
  clear: both;
}

///
/// Fluid Images for responsive designs
///
.fluid-image {
  @include fluid-image;
}

// generic class for inline anchor points
.content-anchor {
  margin-top: -$header-height;
  position: absolute;
}

///
/// breakpoint specific visibility classes
///
.mobile-hidden,
.mobile_hidden {
  @include breakpoint($medium-down) {
    display: none !important;
  }
}

.pc-hidden,
.pc_hidden {
  @include breakpoint($large-up) {
    display: none !important;
  }
}

html.no-scroll,
body.no-scroll {
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
}

// generic class for clickable blocks
.block--linked {
  cursor: pointer;
}

// Default colors
.font--light {
  color: $white;
}

.font--dark {
  color: $black;
}

///
/// content dimmer, usable across the site
.content-dim {
  @include transition(all 0.5s);
  background: $color-white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 49;
  left: 0;
  top: 0;
  opacity: 0;
  height: 100%;
  visibility: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  // On active animate fade in not slide down
  &.active {
    visibility: visible;
    opacity: 1;
  }
  @include breakpoint($large-up) {
    background-image: none !important;
    background: rgba($color-white, 0.5);
  }
  &--loading {
    background: rgba($color-black, 0.6);
    img {
      width: 71px;
      height: 71px;
    }
  }
  &--content {
    background: rgba($color-white, 0.5);
    &.active--gnav {
      @include breakpoint($large-up) {
        background: $color-overlay-dark-light;
      }
    }
  }
  &--top {
    z-index: 99;
  }
}

// Content Block Content Helpers
// Maximum Width
@include breakpoint($medium-up) {
  @include maxWidths;
  .margin-left-5 {
    margin-left: 5%;
  }
  .margin-left-10 {
    margin-left: 10%;
  }
  .margin-left-15 {
    margin-left: 15%;
  }
  .margin-left-20 {
    margin-left: 20%;
  }
  .margin-left-25 {
    margin-left: 25%;
  }
  .margin-left-30 {
    margin-left: 30%;
  }
  .margin-left-35 {
    margin-left: 35%;
  }
  .margin-left-40 {
    margin-left: 40%;
  }
  .margin-left-45 {
    margin-left: 45%;
  }
  .margin-left-50 {
    margin-left: 50%;
  }
  .margin-right-5 {
    margin-right: 5%;
  }
  .margin-right-10 {
    margin-right: 10%;
  }
  .margin-right-15 {
    margin-right: 15%;
  }
  .margin-right-20 {
    margin-right: 20%;
  }
  .margin-right-25 {
    margin-right: 25%;
  }
  .margin-right-30 {
    margin-right: 30%;
  }
  .margin-right-35 {
    margin-right: 35%;
  }
  .margin-right-40 {
    margin-right: 40%;
  }
  .margin-right-45 {
    margin-right: 45%;
  }
  .margin-right-50 {
    margin-right: 50%;
  }
}

// Flexbox
.mobile-flex-grid {
  @include breakpoint($medium-down) {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
}

.pc-flex-grid {
  @include breakpoint($large-up) {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

@mixin flexbox_grid_column_gap($gap, $n) {
  .flex__item {
    width: calc((100% - (#{$gap} * #{$n - 1})) / #{$n});
    margin-right: #{$gap};
    &:nth-child(#{$n}n) {
      margin-right: 0;
    }
  }
}

@mixin flexbox_grid_row_gap($gap, $n) {
  .flex__item {
    margin-top: #{$gap};
    @for $i from 1 through $n - 1 {
      &:nth-child(#{$i}) {
        margin-top: 0;
      }
    }
    &:nth-child(#{$n}) {
      margin-top: 0;
    }
  }
}

@mixin flexbox_grid_gaps_all($n) {
  &[data-grid-column-gap='1%'] {
    @include flexbox_grid_column_gap(1%, $n);
  }
  &[data-grid-column-gap='2%'] {
    @include flexbox_grid_column_gap(2%, $n);
  }
  &[data-grid-row-gap='1%'] {
    @include flexbox_grid_row_gap(1%, $n);
  }
  &[data-grid-row-gap='2%'] {
    @include flexbox_grid_row_gap(2%, $n);
  }
}

.flex-grid {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  .flex__item {
    width: 100%;
    overflow: hidden;
    .mantle-media-asset {
      display: flex;
      background: $color-pink-2;
      picture {
        height: 100%;
        width: 100%;
        img {
          height: inherit;
        }
      }
    }
    picture,
    img,
    video {
      display: block;
      height: auto;
      margin: auto;
    }
    @include breakpoint($medium-up) {
      width: 25%;
    }
  }
  @include breakpoint($small-down) {
    &.flex--small-1 {
      .flex__item {
        width: 100%;
      }
    }
    &.flex--small-2 {
      .flex__item {
        flex: 1 0 50%;
        &:nth-child(odd) > .mantle-media-asset {
          picture,
          video {
            margin-right: 0;
          }
        }
        &:nth-child(even) > .mantle-media-asset {
          picture,
          video {
            margin-left: 0;
          }
        }
        &--min-width {
          min-width: 320px;
        }
      }
    }
    &.flex--small-3 {
      .flex__item {
        flex: 1 0 33%;
        &--min-width {
          min-width: 320px;
        }
      }
    }
    &.flex--small-4 {
      .flex__item {
        flex: 1 0 25%;
        &--min-width {
          min-width: 320px;
        }
      }
    }
  }
  @include breakpoint($medium-up) {
    &.flex--medium-1 {
      .flex__item {
        width: 100%;
      }
    }
    &.flex--medium-2 {
      .flex__item {
        width: 50%;
        &:nth-child(odd) > .mantle-media-asset {
          picture,
          video {
            margin-right: 0;
          }
        }
        &:nth-child(even) > .mantle-media-asset {
          picture,
          video {
            margin-left: 0;
          }
        }
      }
      @include flexbox_grid_gaps_all(2);
    }
    &.flex--medium-3 {
      .flex__item {
        width: 33%;
      }
      @include flexbox_grid_gaps_all(3);
    }
    &.flex--medium-4 {
      .flex__item {
        width: 25%;
      }
      @include flexbox_grid_gaps_all(4);
    }
  }
}

.right-caret {
  @include rightCaret;
}

.gradient-border-bottom {
  @include gradientBorderBottom;
}

.pink-background {
  background: $color-pink-2;
}
