.right-caret {
  @include rightCaret;
}

.gradient-border-bottom {
  @include gradientBorderBottom;
}

.mantle-custom-text.style--bare {
  @include editorial-lists;
}

.product-details__section {
  .custom-text {
    @include editorial-lists;
  }
}

.product-details__section--tips {
  .product-detail-subitem {
    &__text {
      &-title {
        @include tips-header;
      }
    }
  }
}

.foundation-finder-quiz {
  .mantle-form-options-field {
    .madina-title {
      @include madina-title;
    }
  }
}
