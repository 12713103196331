///
/// @file base/_fonts.scss
///
/// \brief Font face rules only.
///

// Note: not using @include font-face from compass to maintain compatibility
// gulp/libsass

// Note: WOFF/WOFF2 are the only formats needed now since we no longer support IE8. https://caniuse.com/#feat=woff

// Proxima Nova Light
@font-face {
  font-family: 'Proxima Nova';
  font-display: swap;
  font-weight: 100;
  src: url('#{$netstorage-fonts-path}ProximaNova/Proxima_Nova_Light.woff2') format('woff2');
}

// Proxima Nova Regular
@font-face {
  font-family: 'Proxima Nova';
  font-display: swap;
  font-weight: normal;
  src: url('#{$netstorage-fonts-path}ProximaNova/Proxima_Nova.woff2') format('woff2');
}

// Proxima Nova Bold
@font-face {
  font-family: 'Proxima Nova';
  font-display: swap;
  font-weight: 700;
  src: url('#{$netstorage-fonts-path}ProximaNova/Proxima_Nova_Bold.woff2') format('woff2');
}

// Bauer Bodoni Bold
@font-face {
  font-family: 'Bauer Bodoni Bold';
  font-display: swap;
  src: url('#{$netstorage-fonts-path}BauerBodoni/Bauer_Bodoni_Bold.woff2') format('woff2');
}

// Bauer Bodoni Bold Italic
@font-face {
  font-family: 'Bauer Bodoni Bold';
  font-display: swap;
  font-style: italic;
  src: url('#{$netstorage-fonts-path}BauerBodoni/Bauer_Bodoni_Bold_Italic.woff2') format('woff2');
}

// Madina Alt
@font-face {
  font-family: 'Madina Alt';
  font-display: swap;
  src: url('#{$netstorage-fonts-path}Madina/madina_clean_alt.woff2') format('woff2');
}

// Gotcha - Regular
@font-face {
  font-family: 'Gotcha Regular';
  font-display: swap;
  src: url('#{$netstorage-fonts-path}Gotcha/Gotcha-Regular.woff2') format('woff2');
}
