///
/// @file base/_forms.scss
///
/// \brief All form elements
///

// Just text inputs

.form--pink {
  background: $color-pink-2;
}

input[type='text'],
input[type='email'],
input[type='password'],
input[type='date'],
input[type='search'],
input[type='tel'],
input[type='number'],
input[type='time'],
input[type='url'],
textarea,
select {
  @include input-styles;
  // If a required input has been filled in and is valid
  &.is-filled-in:required:valid {
    background-image: url('#{$base-theme-path}img/select-box/verification-mark.png');
    background-position: right 50%;
    background-repeat: no-repeat;
  }
  // If a required input has been filled in but is invalid
  &.is-filled-in:required:invalid {
    @include input-placeholder {
      color: $color-error;
    }
    border-color: $color-error;
  }
  .form--pink & {
    border-color: transparent;
  }
}

input[type='submit'] {
  // Remove IOS rounded corners
  border-radius: 0;
}

// Custom checkbox
input[type='checkbox'] {
  // Remove IOS rounded corners
  border-radius: 0;
  &.is-filled-in:required:invalid + label {
    color: $color-error;
  }
  &,
  & + .label {
    display: inline-block;
  }
}

// Custom radio
input[type='radio'] {
  &,
  & + .label {
    display: inline-block;
  }
}

textarea {
  height: 90px;
  line-height: $base-line-height;
  padding: 1.5rem 15px 10px;
}

// Stylized native select
select {
  @include appearance(none);
  cursor: pointer;
  min-width: 60px;
  border: 1px solid $color-pink-1;
  border-radius: 0;
  color: $color-dark-gray;
  font-size: $base-font-size;
  height: 40px;
  line-height: 40px;
  padding: 0 25px 0 15px;
  background-color: $color-white;
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 10px;
  // Retina-friendly default
  background-image: url('#{$base-theme-path}svg-icons/src/arrow--down.svg');
  .no-svg & {
    // Old browsers
    background-image: url('#{$base-theme-path}svg-icons/src/arrow--down.svg');
  }
  &:focus {
    outline: 0;
    border-color: $color-focus;
  }
  &::-ms-expand {
    display: none;
  }
}
