///
/// @file base/_typography--helpers.scss
///
/// \brief Reusable, generic typographic styles used throughout the site.
///
/// Guidelines:
///
/// Generic classes we can use sitewide for display elements.
/// WARNING do not change values unless you know why you are here
/// these classes are used in mustache for touts
/// @setup new brand - apply consistently to match your designs

// Font Colors

.color-pink-1 {
  color: $color-pink-1;
}

.color-pink-2 {
  color: $color-pink-2;
}

.color-pink-accent {
  color: $color-pink-accent;
}

.color-black {
  color: $color-black;
}

.color-gold {
  color: $color-gold;
}

.color-teal {
  color: $color-teal;
}

.color-yellow {
  color: $color-yellow;
}

.color-mint {
  color: $color-mint;
}

.color-white {
  color: $color-white;
}

.header-large {
  @include header-large;
}

.header-medium {
  @include header-medium;
}

.header-small {
  @include header-small;
}

.title-large {
  @include title-large;
}

.title-small {
  @include title-small;
}

.eyebrow-large {
  @include eyebrow-large;
}

.eyebrow-small {
  @include eyebrow-small;
}

.body-large {
  @include body-large;
}

.body-medium {
  @include body-medium;
}

.body-small {
  @include body-small;
}

.body-x-small {
  @include body-x-small;
}

.fine-print {
  @include fine-print;
}

// TODO: Remove t- styles after we have confirmed no holdover style usage in CMS
.t1 {
  @include header-large;
}

.t2 {
  @include header-large;
}

.t3 {
  @include header-large;
}

.t4 {
  @include body-large;
}

.t5 {
  @include header-medium;
}

.t6 {
  @include body-medium;
}

.t7 {
  @include header-medium;
}

.t8 {
  @include body-large;
}

.t9 {
  @include title-large;
}

.t10 {
  @include eyebrow-small;
}

.t11 {
  @include fine-print;
}

.t12 {
  @include body-medium;
}

.t13 {
  @include body-small;
}

.t14 {
  @include body-small;
}

.t15 {
  @include title-small;
}

.t16 {
  @include header-large;
}

.t17 {
  @include header-small;
}

.t18 {
  @include body-small;
}

.t19 {
  @include header-large;
}

.t20 {
  @include title-large;
}

.t21 {
  @include body-medium;
}

.t22 {
  @include body-small;
}

.t23 {
  @include title-large;
}

.t24 {
  @include title-small;
}

.t25 {
  @include body-medium;
}

.t26 {
  @include body-small;
}

.t27 {
  @include header-small;
}

.t28 {
  @include body-small;
}

ul.inline-bullets {
  @include inline-bullets;
}

ul.icon-list {
  @include icon-list;
}

.madina-title {
  @include madina-title;
}

.text--tips-header {
  @include tips-header;
}

// This is here for backward compat since the class was entered in the RTE.
.text--border-underline {
  @include details-section-header;
}

.text--details-section-header {
  @include details-section-header;
}

.goldbar-header {
  @include goldbar-header;
}

@each $spacing in $vertical-spacing-styleguide {
  $i: index($vertical-spacing-styleguide, $spacing);
  .margin-vertical-#{$i} {
    margin-top: mobilePx($spacing);
    margin-bottom: mobilePx($spacing);
    @include breakpoint($medium-up) {
      margin-top: tabletPx($spacing);
      margin-bottom: tabletPx($spacing);
    }
    @include breakpoint($landscape-up) {
      margin-top: $spacing;
      margin-bottom: $spacing;
    }
  }
  .margin-top-#{$i} {
    margin-top: mobilePx($spacing);
    @include breakpoint($medium-up) {
      margin-top: tabletPx($spacing);
    }
    @include breakpoint($landscape-up) {
      margin-top: $spacing;
    }
  }
  .margin-bottom-#{$i} {
    margin-bottom: mobilePx($spacing);
    @include breakpoint($medium-up) {
      margin-bottom: tabletPx($spacing);
    }
    @include breakpoint($landscape-up) {
      margin-bottom: $spacing;
    }
  }
  .padding-vertical-#{$i} {
    padding-top: mobilePx($spacing);
    padding-bottom: mobilePx($spacing);
    @include breakpoint($medium-up) {
      padding-top: tabletPx($spacing);
      padding-bottom: tabletPx($spacing);
    }
    @include breakpoint($landscape-up) {
      padding-top: $spacing;
      padding-bottom: $spacing;
    }
  }
  .padding-top-#{$i} {
    padding-top: mobilePx($spacing);
    @include breakpoint($medium-up) {
      padding-top: tabletPx($spacing);
    }
    @include breakpoint($landscape-up) {
      padding-top: $spacing;
    }
  }
  .padding-bottom-#{$i} {
    padding-bottom: mobilePx($spacing);
    @include breakpoint($medium-up) {
      padding-bottom: tabletPx($spacing);
    }
    @include breakpoint($landscape-up) {
      padding-bottom: $spacing;
    }
  }
}

@each $spacing in $horizontal-spacing-styleguide {
  $i: index($horizontal-spacing-styleguide, $spacing);
  .margin-horizontal-#{$i} {
    margin-left: mobilePx($spacing);
    margin-right: mobilePx($spacing);
    @include breakpoint($medium-up) {
      margin-left: tabletPx($spacing);
      margin-right: tabletPx($spacing);
    }
    @include breakpoint($landscape-up) {
      margin-left: $spacing;
      margin-right: $spacing;
    }
  }
  .margin-left-#{$i} {
    margin-left: mobilePx($spacing);
    @include breakpoint($medium-up) {
      margin-left: tabletPx($spacing);
    }
    @include breakpoint($landscape-up) {
      margin-left: $spacing;
    }
  }
  .margin-right-#{$i} {
    margin-right: mobilePx($spacing);
    @include breakpoint($medium-up) {
      margin-right: tabletPx($spacing);
    }
    @include breakpoint($landscape-up) {
      margin-right: $spacing;
    }
  }
  .padding-horizontal-#{$i} {
    padding-left: mobilePx($spacing);
    padding-right: mobilePx($spacing);
    @include breakpoint($medium-up) {
      padding-left: tabletPx($spacing);
      padding-right: tabletPx($spacing);
    }
    @include breakpoint($landscape-up) {
      padding-left: $spacing;
      padding-right: $spacing;
    }
  }
  .padding-left-#{$i} {
    padding-left: mobilePx($spacing);
    @include breakpoint($medium-up) {
      padding-left: tabletPx($spacing);
    }
    @include breakpoint($landscape-up) {
      padding-left: $spacing;
    }
  }
  .padding-right-#{$i} {
    padding-right: mobilePx($spacing);
    @include breakpoint($medium-up) {
      padding-right: tabletPx($spacing);
    }
    @include breakpoint($landscape-up) {
      padding-right: $spacing;
    }
  }
}
