///
/// @file base/_base.scss
///
/// \brief Fonts, line-heights and re-usable text styles
///
/// Generic, base styles for common elements.
///

// BORDER-BOX ALL THE THINGS! (http://paulirish.com/2012/box-sizing-border-box-ftw/)
html {
  @include box-sizing(border-box);
  &.custom-pointer {
    cursor: url('/media/images/global/custom-pointer.png'), auto;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

p {
  margin: 0 0 1em 0;
}

a {
  color: $color-link;
  transition: color 0.3s;
  outline: none;
  &:hover {
    color: $color-link-hover;
  }
  &:focus {
    outline: 0;
    .ada-enabled & {
      outline: $outline;
    }
  }
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

label {
  cursor: pointer;
}

ul,
ol,
dl,
dt,
dd {
  margin: 0;
  padding: 0;
}

ul,
ol {
  list-style-type: none;
}

@mixin default-ul() {
  list-style-type: disc;
  margin-bottom: 20px;
  padding-left: 20px;
}

@mixin default-ol() {
  list-style-type: decimal;
  margin-bottom: 20px;
  padding-left: 15px;
}

// Bring back list defaults
ul.default-ul {
  @include default-ul;
}

ol.default-ol {
  @include default-ol;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid $color-lighter-gray;
  margin: 1em 0;
  padding: 0;
}

// Remove the gap between images, videos, audio and canvas and the bottom of (h5bp.com/i/440)
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

img,
picture {
  max-width: 100%;
}

.lazyload {
  opacity: 0 !important;
}

.lazyloading {
  opacity: 0 !important;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

// Hide alt if image is missing a src attr
img:not([src])[alt] {
  opacity: 0;
}
