///
/// @file base/_typography.scss
///
/// \brief Reusable, generic typographic styles used throughout the site.
///
/// Guidelines:
///
/// No layout-related properties (float, margin, padding, position, display,
/// etc.), no template-specific stuff, try to follow the existing naming
/// conventions and method of creating both mixins and extendable placeholders.

// @todo remove the extends - this method is deprecated

body {
  color: $color-text;
  font-family: $font--text;
  font-size: $base-font-size;
  line-height: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  // We reset default heading styles. For custom header styles see _typography--helpers.scss
  font-size: $base-font-size;
  line-height: normal;
  font-weight: normal;
  margin: 0;
}

// wysiwyg automatically inserts <p> in rich_text mode
.heading {
  > p {
    line-height: inherit;
    margin: 0;
  }
}

input[type='button'],
input[type='submit'],
input[type='reset'],
input[type='file']::-webkit-file-upload-button,
button {
  border-width: 0;
}

// build each media query for js ingestion
@each $mq in $mq-sync {
  @media screen and (min-width: nth($mq, 2)) {
    head {
      font-family: '#{nth($mq, 1)} #{nth($mq, 2)}';
    }
  }
}

head {
  // set clear on head to show Unison is set up correctly
  clear: both;
  // store hash of all breakpoints
  title {
    font-family: '#{$mq-sync}';
  }
}

/// font faces
.font--bodoni-bold,
.font--secondary {
  @include font--bodoni-bold;
}

.font--proxima-nova,
.font--default {
  @include font--proxima-nova;
}

.font--madina-alt {
  @include font--madina-alt;
}

// Font weight and style
.font-weight-light {
  font-weight: 100;
}

.font-weight-semibold {
  font-weight: 600;
}

.font-weight-bold {
  font-weight: 700;
}

.font-style-italic {
  font-style: italic;
}
